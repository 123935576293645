import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Wild Rockets`}</h1>
    <p>{`Wild Rockets är en videoautomat som har skapats av Net Enterainment och hade lansering i 2012. Videoautomaten tema är runt fyrverkeri-pjäser och smällare, alla symbolerna i spelet är olika fyrverkeri-pjäser, smällare och de klassiska 10 – J – Q – K – A symbolerna.`}</p>
    <h2>{`720 insats linjer`}</h2>
    <p>{`Vad som är unikt med detta spelet är att man spelar på 720 insats linjer istället för de 20 som är det vanliga med andra spelautomater. Detta är den enda spelautomaten i världen som erbjuder spel på 720 insatts linjer och gör den verkligen till en unik spelautomat.`}</p>
    <h2>{`Wild symbol raketen`}</h2>
    <p>{`Wild symbolen i Wild Rockets representera alla symboler i spelet och kan därför användas för att komplettera alla 720 rader som finns i spelet. Vad som är extra unikt är att när wild symbolen kommer fram på ett av hjulen så expandera den ut över hela hjulet uppåt eller neråt beroende på vilket håll raketen riktar åt. När wild symbolen expandera så gör den det med en häftig effekt med smällare och raketer.`}</p>
    <h2>{`Free spins`}</h2>
    <p>{`När man spelar wild rockets så går det att vinna upp till 50 free spins från en spelrunda. För att vinna free spins så behöver man få minst 3 scatter symboler på hjulen totalt och dom behöver inte vara i rad efter varandra. Man får free spins enligt följande:
3 scatter = 10 free spins
4 scatter = 20 free spins
5 scatter = 50 free spins`}</p>
    <h2>{`Vinn upp till 480 000 mynt`}</h2>
    <p>{`Det går att vinna så mycket som 480 000 mynt på en spelrunda i Wild Rockets och spelar man med ett mynt värde på 5kr och insatts nivå 4 och vinner 480 000 mynt så vinner man så mycket som 9.6 miljoner kronor ifrån en enda spelrunda.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      